/**
 * Administrar los aus
 */
import utils from '@/utils';
import api from '@/plugins/api';

const getters = {
	subjectList: state => {
		let subjects = state.subjects;
		subjects = utils.deepCopy(subjects);
		utils.sortData(subjects, 'published_at', 'ASC');

		return subjects
			.filter(item => item.type == state.selectedEventType)
			.filter(item => item.association_area.code == state.selectedArea);
	},
};

const state = {
	selectedEventType: 'requestforattention',
	selectedArea: 'watchman',

	/* Lista de asuntos */
	subjects: [],
};

const actions = {
	addSubject({ dispatch }, data) {
		/* Agregar asuntos */
		return new Promise((resolve, reject) => {
			api()
				.post(`/event-book-options`, data)
				.then(() => {
					dispatch('loadSubjects');
					resolve();
				})
				.catch(() => {
					reject();
				});
		});
	},

	deleteSubject({ dispatch }, id) {
		/* Borrar asuntos */
		return new Promise((resolve, reject) => {
			api()
				.delete(`/event-book-options/${id}`)
				.then(() => {
					dispatch('loadSubjects');
					resolve();
				})
				.catch(() => {
					reject();
				});
		});
	},

	/* Lista de asuntos propuestos para los formularios */
	loadSubjects({ commit }) {
		return new Promise((resolve, reject) => {
			api()
				.get(`/event-book-options`)
				.then(res => {
					commit('setSubjects', res.data);
					resolve();
				})
				.catch(() => {
					reject();
				});
		});
	},

	changeArea({ commit }, areacode) {
		commit('setSelectedArea', areacode);
	},

	changeEventType({ commit }, eventtype) {
		commit('setSelectedEventType', eventtype);
	},
};

const mutations = {
	/* Almacenar asuntos posibles en los formularios */
	setSubjects(state, data) {
		state.subjects = data;
	},

	setSelectedEventType(state, data) {
		state.selectedEventType = data;
	},

	setSelectedArea(state, data) {
		state.selectedArea = data;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
