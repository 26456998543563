/**
 * Este archivo es para centrar la logica referente a los eventos que se presentan
 * a los canales y a los regantes.
 *
 * Nos encontramos en una version de desarrollo y de mejora constante.
 */
import utils from '@/utils';
import api from '@/plugins/api';
import { socket } from '@/plugins/web-sockets';

const perPage = Number(process.env.VUE_APP_EVENTS_PER_PAGE);

const getters = {
	messageList: state => {
		let messages = state.selectedEventMessages;
		/* Se ordena */
		if (state.selectedEventMessages && state.selectedEventMessages.length) {
			messages = utils.deepCopy(messages);
			utils.sortData(messages, 'published_at', 'ASC');
		}

		return messages;
	},
};

const state = {
	selectedEventType: 'requestforattention',

	/* La lista de eventos que se pueden hacer en la aplicacion. Este se usa para almacenar los eventos del usuario como tambien para los formularios de creacion de eventos */
	eventTypes: [
		{
			type: 'requestforattention',
			name: 'Solicitud de atención',
			class: 'blue white--text',
			color: 'blue',
		},
		{
			type: 'channelreport',
			name: 'Reporte de canal',
			class: 'green white--text',
			color: 'green',
		},
		{
			type: 'visitreport',
			name: 'Reporte de visita',
			class: 'yellow',
			color: 'yellow',
		},
		{
			type: 'annotation',
			name: 'Anotación',
			class: 'purple darken-1 white--text',
			color: 'purple darken-1',
		},
	],

	/* Lista de eventos traidos del endpoint */
	events: [],
	eventsLastVisible: { count: 0, next: 0, noMore: true },
	statistics: {},

	optionsEventBook: [],

	selectedEvent: {},

	selectedEventMessages: [],
	selectedEventMessagesLastVisible: {},
};

const actions = {
	/**
	 * Retorna una lista de eventos
	 * Parametros en dataOptions: irrigatorCode, areaID, type
	 * */
	loadEvents({ commit, state }, dataOptions) {
		return new Promise(resolve => {
			let query = `/event-books?`;
			let countQuery = `/event-books/count?`;
			let parameters = '';

			if (dataOptions.watchman) {
				parameters = `&watchman=${dataOptions.watchman}`;
				query += parameters;
				countQuery += parameters;
			}

			if (dataOptions.irrigatorCode) {
				parameters = `&irrigator_code=${dataOptions.irrigatorCode}`;
				query += parameters;
				countQuery += parameters;
			}

			if (dataOptions.areaID) {
				parameters = `&association_area=${dataOptions.areaID}`;
				query += parameters;
				countQuery += parameters;
			}

			if (dataOptions.type) {
				parameters = `&type=${dataOptions.type}`;
				query += parameters;
				countQuery += parameters;
			}

			if (dataOptions.dateStart) {
				parameters = `&createdAt_gte=${dataOptions.dateStart}`;
				query += parameters;
				countQuery += parameters;
			}

			if (dataOptions.dateEnd) {
				parameters = `&createdAt_lte=${dataOptions.dateEnd}`;
				query += parameters;
				countQuery += parameters;
			}

			if (dataOptions.closed === false || dataOptions.closed === true) {
				parameters = `&closed=${dataOptions.closed}`;
				query += parameters;
				countQuery += parameters;
			}
			if (dataOptions.allEvents) {
				parameters = `&allEvents=${dataOptions.allEvents}`;
				query += parameters;
				countQuery += parameters;
			}

			query += `&_sort=createdAt:desc&_start=${dataOptions.next}&_limit=${perPage}`;

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.get(query)
				.then(async res => {
					dataOptions.count = await api().get(countQuery);
					dataOptions.count = dataOptions.count.data;
					commit('setEvents', res.data);

					/* Incremento el parametro next */
					dataOptions.next += perPage;

					/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
					if (dataOptions.next >= dataOptions.count) {
						dataOptions.next = dataOptions.count;
						dataOptions.noMore = true;
					} else {
						dataOptions.noMore = false;
					}

					/* Guardo en el state los parametros de la paginacion */
					const last = {
						...utils.deepCopy(state.eventsLastVisible),
						...dataOptions,
					};
					commit('setEventsLastVisible', last);

					resolve();
				})
				.finally(() => {
					this.dispatch('systemNotification/toggleLoading', false);
				});
		});
	},

	async loadstatistics({ commit }, dataOptions) {
		let countQuery = `/event-books/count?`;
		let totalReveided = 0;
		let totalClosed = 0;
		let totalRequestForAttention = 0;
		let totalOpen = 0;

		/* Viene el ID del celador */
		if (dataOptions.type === 'watchman') {
			countQuery += `&irrigator_watchman=${dataOptions.association_user}`;
		}

		/* Viene el ID del Jefe de section */
		if (dataOptions.type === 'sectionm') {
			countQuery += `&irrigator_sectionm=${dataOptions.association_user}`;
		}

		await api()
			.get(countQuery)
			.then(async res => {
				totalReveided = res.data;
			});
		await api()
			.get(`${countQuery}&closed=true`)
			.then(async res => {
				totalClosed = res.data;
			});
		await api()
			.get(`${countQuery}&type=requestforattention&closed=false`)
			.then(async res => {
				totalRequestForAttention = res.data;
			});
		await api()
			.get(`${countQuery}&closed=false`)
			.then(async res => {
				totalOpen = res.data;
			});

		commit('setStatistics', {
			totalReveided,
			totalClosed,
			totalRequestForAttention,
			totalOpen,
		});
	},

	downloadEventsReport(context, dataOptions) {
		return new Promise(resolve => {
			let query = `/event-books-report?`;
			let parameters = '';

			if (dataOptions.watchman) {
				parameters = `&watchman=${dataOptions.watchman}`;
				query += parameters;
			}

			if (dataOptions.irrigatorCode) {
				parameters = `&irrigator_code=${dataOptions.irrigatorCode}`;
				query += parameters;
			}

			if (dataOptions.areaID) {
				parameters = `&association_area=${dataOptions.areaID}`;
				query += parameters;
			}

			if (dataOptions.type) {
				parameters = `&type=${dataOptions.type}`;
				query += parameters;
			}

			if (dataOptions.dateStart) {
				parameters = `&createdAt_gte=${dataOptions.dateStart}`;
				query += parameters;
			}

			if (dataOptions.dateEnd) {
				parameters = `&createdAt_lte=${dataOptions.dateEnd}`;
				query += parameters;
			}

			if (dataOptions.closed === false || dataOptions.closed === true) {
				parameters = `&closed=${dataOptions.closed}`;
				query += parameters;
			}

			query += `&_sort=createdAt:desc&_limit=1000000`;

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.get(query, { responseType: 'blob' })
				.then(res => {
					const type = res.headers['content-type'];
					const url = window.URL.createObjectURL(
						new Blob([res.data], { type: type, encoding: 'UTF-8' })
					);
					const link = document.createElement('a');
					const date = utils.moment().format('YYYY-MM-DD-HH-mm-ss');
					link.href = url;
					link.download = `Reporte-${date}.xlsx`;
					link.click();
					window.URL.revokeObjectURL(link.href);
				})
				.finally(() => {
					this.dispatch('systemNotification/toggleLoading', false);
					resolve();
				});
		});
	},

	addEvent({ state, commit, dispatch }, data) {
		/* Agregar eventos */
		return new Promise(resolve => {
			// Borrado de los atributos del objeto que estan vacios
			for (const property in data) {
				if (!data[property]) delete data[property];
			}
			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/event-books`, data)
				.then(() => {
					let lastVisible = utils.deepCopy(state.eventsLastVisible);
					lastVisible = {
						...lastVisible,
						...{ count: 0, next: 0, noMore: true },
					};
					commit('setEventsLastVisible', lastVisible);
					commit('setEventsClear');
					dispatch('unselectEvent');
					dispatch('loadEvents', lastVisible);
					resolve();
				})
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	resetEvents({ commit }) {
		commit('setEventsClear');
	},

	resetEventsLastVisible({ commit }) {
		commit('setEventsLastVisibleClear');
	},

	/* Selecciona el evento y se setea los mensajes contenidos */
	async selectEvent({ commit, dispatch }, data) {
		commit('setSelectedEvent', data);

		/* Se abre el socket en este evento, agregandose al usuario a una sala de este evento */
		socket.emit('open:chat', data.id);

		dispatch('loadEventMessages');
	},

	// Cargar los mensajes mas recientes
	loadEventMessages({ commit, state }) {
		return new Promise(resolve => {
			if (state.selectedEvent.id) {
				/* Se optiene de manera descendente desde la base de datos, para recibir el ultimo
				siempre de primero y en caso de necesitar mostrar mas, se usa el paginado, pero en
				la vista del usuario se debe mostras en orden ASC para que el ultimo recibido, se
				quede siempre abajo */
				this.dispatch('systemNotification/toggleLoading', true);
				api()
					.get(`/get-messages/${state.selectedEvent.id}`)
					.then(async res => {
						commit('setSelectedEventMessages', res.data.messages);
						resolve();
					})
					.finally(() =>
						this.dispatch('systemNotification/toggleLoading', false)
					);
			}
		});
	},

	/* Lista de asuntos propuestos para los formularios */
	loadEventSubjects({ commit }) {
		api()
			.get(`/event-book-options`)
			.then(res => {
				commit('setEventSubjects', res.data);
			});
	},

	/* Cerrar chat */
	unselectEvent({ commit }) {
		commit('setSelectedEvent', {});
		commit('setSelectedEventMessages', []);

		/* Se abre el socket en este evento, agregandose al usuario a una sala de este evento */
		socket.emit('open:chat', 0);
	},

	/* Actualizar eventos ya cargados en el stora */
	updateEventOfList({ commit, state }, data) {
		const list = utils.deepCopy(state.events);

		list.forEach((item, index) => {
			if (item.id == data.id) list[index] = data;
		});

		commit('setEventsUpdated', list);
	},

	toggleEvent({ state, dispatch, commit }, data) {
		return new Promise(resolve => {
			data.event_book = state.selectedEvent.id;
			this.dispatch('systemNotification/toggleLoading', true);

			api()
				.post(`/event-book-messages`, data)
				.then(res => {
					/* Se agrega un mensaje al evento */
					socket.emit('message:chat', res.data);

					dispatch('pushMessage', res.data);

					if (data.closed != state.selectedEvent.closed) {
						let event = utils.deepCopy(state.selectedEvent);
						event.closed = data.closed;

						commit('setSelectedEvent', event);
						/* Actualizar la lista existente */
						dispatch('updateEventOfList', event);
					}

					if (data.transferred_to) {
						let lastVisible = utils.deepCopy(
							state.eventsLastVisible
						);
						lastVisible = {
							...lastVisible,
							...{ count: 0, next: 0, noMore: true },
						};
						commit('setEventsLastVisible', lastVisible);
						commit('setEventsClear');
						dispatch('unselectEvent');
						dispatch('loadEvents', lastVisible);
					}

					resolve();
				})
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	/* Mensajes dentro de los eventos */

	addEventMessage({ state, dispatch }, data) {
		return new Promise(resolve => {
			data.event_book = state.selectedEvent.id;
			this.dispatch('systemNotification/toggleLoading', true);

			api()
				.post(`/event-book-messages`, data)
				.then(res => {
					/* Se agrega un mensaje al evento */
					socket.emit('message:chat', res.data);

					dispatch('pushMessage', res.data);

					resolve();
				})
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	addFileAttach({ state, dispatch }, data) {
		const fileTypes = [
			'image/jpeg',
			'image/png',
			'application/pdf',
			'application/wps-office.pptx',
			'application/wps-office.ppt',
			'application/wps-office.docx',
			'application/wps-office.doc',
			'application/wps-office.xlsx',
			'application/wps-office.xls',
		];

		if (fileTypes.includes(data.file.type)) {
			var formdata = new FormData();
			formdata.append('files.attached', data.file, data.file.name);
			formdata.append(
				'data',
				JSON.stringify({ event_book: state.selectedEvent.id })
			);

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/event-book-messages`, formdata)
				.then(res => {
					/* Se agrega un mensaje al evento */
					socket.emit('message:chat', res.data);

					dispatch('pushMessage', res.data);
				})
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		} else {
			this.dispatch('systemNotification/toggleLoading', false);

			this.dispatch('systemNotification/activeAlert', {
				type: 'error',
				message:
					'Archivo no ha sido cargado. Solo puede enviar Imagenes JPG, PNG; Archivos Excel, Word, PowerPoint y PDF',
			});
		}
	},

	pushMessage({ state, commit }, data) {
		let messages = utils.deepCopy(state.selectedEventMessages);

		messages.push(data);

		commit('setSelectedEventMessages', messages);
	},
};

const mutations = {
	setEvents(state, data) {
		state.events = state.events.concat(data);
	},

	setStatistics(state, data) {
		state.statistics = data;
	},

	/* Eventos ya cargados pero que se requiere actualizar un item existente */
	setEventsUpdated(state, data) {
		state.events = data;
	},

	setEventsLastVisible(state, data) {
		// Busca en el state el tipo de evento que estamos consultando y se actualiza los parametros, el resto se le deja su mismo valor
		state.eventsLastVisible = data;
	},

	setEventTypes(state, data) {
		state.eventTypes = state.eventTypes.map(item => {
			if (item.type == state.selectedEventType) {
				item.events = item.events.concat(data);
			}
			return item;
		});
	},

	/* Almacenar asuntos posibles en los formularios */
	setEventSubjects(state, data) {
		state.eventTypes.forEach(item => {
			item.possibleSubjects = data
				.filter(element => element.type == item.type)
				.map(subj => ({
					subject: subj.subject,
					association_area: subj.association_area.id,
				}));

			if (item.possibleSubjects.length == 0) delete item.possibleSubjects;
		});
	},

	setEventsClear(state) {
		state.events = [];
	},

	setEventsLastVisibleClear(state) {
		state.events = [];
		state.eventsLastVisible = { count: 0, next: 0, noMore: true };
	},

	setSelectedEvent(state, data) {
		state.selectedEvent = data;
	},

	setSelectedEventMessages(state, data) {
		state.selectedEventMessages = data;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
