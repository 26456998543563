import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import acmControl from './modules/acmControl';
import eventBookControl from './modules/eventBookControl';
import eventBookSubjectsControl from './modules/eventBookSubjectsControl';
import filesControl from './modules/filesControl';
import irrigatorControl from './modules/irrigatorControl';
import systemNotification from './modules/systemNotification';
import userControl from './modules/userControl';

const store = new Vuex.Store({
	modules: {
		acmControl,
		eventBookControl,
		eventBookSubjectsControl,
		filesControl,
		irrigatorControl,
		systemNotification,
		userControl,
	},
});

export default store;
