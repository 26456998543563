import api from '@/plugins/api';

const state = {
	acmInfo: {},
};

const actions = {
	/* Datos del area de informacion de la laguna y canales */
	readAcmInfo({ commit }) {
		return new Promise((resolve, reject) => {
			this.dispatch('systemNotification/toggleLoading', true);

			api()
				.get(`/acm-infos/`)
				.then(res => {
					if (res.data.length) commit('setAcmInfo', res.data[0]);

					resolve();
				})
				.catch(err => {
					reject(err);
				})
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	saveAcmInfo({ state, commit }, data) {
		return new Promise((resolve, reject) => {
			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.put(`/acm-infos/${state.acmInfo.id}`, data)
				.then(res => {
					commit('setAcmInfo', res.data);

					resolve();
				})
				.catch(() => reject())
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},
};

const mutations = {
	setAcmInfo(state, data) {
		state.acmInfo = data;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
