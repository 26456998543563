import api from '@/plugins/api';

const perPage = Number(process.env.VUE_APP_IRRIGATORS_PER_PAGE);

const state = {
	isLoading: false,
	alert: {
		type: 'error',
		message: '',
		show: false,
	},

	notifications: [],
	notificationsLastVisible: { count: 0, next: 0 },
	noMoreNotifications: false,
};

const actions = {
	toggleLoading({ commit }, data) {
		commit('setLoading', data);
	},

	activeAlert({ commit }, data) {
		data.show = true;

		commit('setAlert', data);

		setTimeout(() => {
			commit('setAlert', {
				type: '',
				message: '',
				show: false,
			});
		}, 5000);
	},

	/* Lista de metodos para las Notificaciones que serán programadas para que se visualicen desde App */
	loadNotifications({ dispatch, state, commit }, dataOptions) {
		return new Promise(resolve => {
			dispatch('toggleLoading', true);
			api()
				.get(
					`/notification-centers?_sort=createdAt:desc&_start=${dataOptions.next}&_limit=${perPage}`
				)
				.then(async res => {
					if (dataOptions.next) {
						/* Si next esta definido y es mayor que cero, concateno la respuesta al resultado existente */
						const data = state.notifications.concat(res.data);
						commit('setNotifications', data);
					} else {
						/* Si next no esta definido, seteo el contador de registros y el state de resultados */
						dataOptions.count = await api().get(
							`/notification-centers/count`
						);
						dataOptions.count = dataOptions.count.data;
						commit('setNotifications', res.data);
					}

					/* Incremento el parametro next */
					dataOptions.next += perPage;

					/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
					if (dataOptions.next >= dataOptions.count) {
						dataOptions.next = dataOptions.count;
						commit('setNoMoreNotifications', true);
					}

					/* Guardo en el state los parametros de la paginacion */
					commit('setNotificationsLastVisible', {
						count: dataOptions.count,
						next: dataOptions.next,
					});

					resolve();
				})
				.finally(() => dispatch('toggleLoading', false));
		});
	},

	addNotification({ dispatch }, data) {
		// Agregar notificaciones
		return new Promise(resolve => {
			dispatch('toggleLoading', true);
			api()
				.post(`/notification-centers`, data)
				.then(() => {
					resolve();
				})
				.finally(() => dispatch('toggleLoading', false));
		});
	},

	deleteNotification({ dispatch }, data) {
		/* Eliminado la notificacion */
		return new Promise(resolve => {
			dispatch('toggleLoading', true);
			api()
				.delete(`/notification-centers/${data}`)
				.then(() => {
					resolve();
				})
				.finally(() => dispatch('toggleLoading', false));
		});
	},
};

const mutations = {
	setLoading(state, data) {
		state.isLoading = data;
	},

	setAlert(state, data) {
		state.alert = data;
	},

	setNotifications(state, data) {
		state.notifications = data;
	},

	setNoMoreNotifications(state, data) {
		state.noMoreNotifications = data;
	},

	setNotificationsLastVisible(state, data) {
		state.notificationsLastVisible = data;
	},
};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
