import moment from 'moment';

const replacer = (key, value) => {
	return value;
};

export default {
	moment(...a) {
		return moment(...a);
	},

	deepCopy(element) {
		return JSON.parse(JSON.stringify(element, replacer));
	},

	setItemStorage(name, value, type = 'local') {
		let storage = type == 'session' ? sessionStorage : localStorage;
		storage.setItem(name, JSON.stringify(value, replacer));
	},

	getItemStorage(name, type = 'local') {
		let storage = type == 'session' ? sessionStorage : localStorage;
		return JSON.parse(storage.getItem(name));
	},

	getDateFormat(obj) {
		let date =
			obj.format == undefined
				? moment(obj.date)
				: moment(obj.date, obj.format);
		return date.format('MMM D,YYYY');
	},

	sortData(objs, prop, type) {
		/* Ordenar arraglo de objetos */
		objs.sort(function (a, b) {
			if (type.toLowerCase() == 'asc') {
				if (a[prop] > b[prop]) return 1;
				if (a[prop] < b[prop]) return -1;
			} else {
				if (a[prop] > b[prop]) return -1;
				if (a[prop] < b[prop]) return 1;
			}
			/* a debe ser igual a b */
			return 0;
		});
	},

	printMoney(num) {
		//return Number.isInteger( num ) ? 'CLP ' + num : 'CLP ' + parseFloat( num ).toFixed( 2 );
		return num
			? num.toLocaleString('es-cl', {
					style: 'currency',
					currency: 'CLP',
			  })
			: 0;
	},

	formatBytes(bytes, decimals = 2) {
		if (bytes === 0) return '0 Bytes';

		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return (
			parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
		);
	},

	async getReference(documentReference) {
		/* Devuelve los valores de un documento Firestore */
		const res = await documentReference.get();
		const data = res.data();

		if (data && documentReference.id) {
			data.id = documentReference.id;
		}

		return data;
	},

	capitalize(sentence) {
		if (typeof sentence !== 'string') return '';

		/* Se elimina los espacios en blanco en ambos extremos de la oración, se pasa todo a minuscula y se pasa a array si tiene punto */
		let words = sentence.trim().toLowerCase().split('.');
		/* Vuelvo a unir las palabras con punto y un espacio en blanco, para los casos donde las palabras no se encuentren separadas del punto */
		words = words.join('. ');

		/* Se separa por espacios en blanco */
		words = words.split(' ');

		/* Se eliminan los items vacios */
		words = words.filter(item => item.length);
		/* Se pasa a Capitalcase */
		words.forEach(
			(item, index) =>
				(words[index] = item.charAt(0).toUpperCase() + item.slice(1))
		);
		/* Se vuelve a juntar las letras */
		words = words.join(' ');

		return words;
	},
};
