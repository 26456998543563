import utils from '@/utils';
import api from '@/plugins/api';
import { socket } from '@/plugins/web-sockets';

const storageName = process.env.VUE_APP_STORAGE_NAME,
	allowedRoles = ['watchman', 'sectionm', 'adminacm', 'association'];

const perPage = Number(process.env.VUE_APP_IRRIGATORS_PER_PAGE);

const state = {
	user: {},
	generalSettings: {},
	type: null,

	users: [],
	usersLastVisible: { count: 0, next: 0 },
	noMoreUsers: false,

	associationAreas: [],
	selectedUser: {},
	channels: [],

	watchmans: [] /* Lista entera de los celadores */,
	sectionm: [] /* Lista entera de los jefes de seccion */,

	disconnected: false,
};

const actions = {
	loginUser({ commit, dispatch }, userLogin) {
		return new Promise((resolve, reject) => {
			api()
				.post('/auth/local', {
					identifier: userLogin.email,
					password: userLogin.password,
				})
				.then(res => {
					commit('setSession', res.data);
					dispatch('activeUser');

					if (allowedRoles.includes(res.data.user.role.type)) {
						commit('setType', res.data.user.role.type);
						resolve();
					} else {
						/* Cierra sesión porque no es ninguno de los roles esperado */
						dispatch('logout');
						reject();
					}
				})
				.catch(err => {
					if (err.response) {
						/* Envio solo el primer mensaje */
						err = err.response.data.message[0].messages[0];
					}
					reject(err);
				});
		});
	},

	resetPasswordUser(context, email) {
		return new Promise((resolve, reject) => {
			api()
				.post('/auth/forgot-password', { email })
				.then(() => {
					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	/* Si existe un token se actualizan los datos del usuario, en caso contrario se limpia el storage */
	isTheUserActive({ dispatch, commit }) {
		return new Promise((resolve, reject) => {
			api()
				.get('/users/me')
				.then(async res => {
					await dispatch('activeUser');
					commit('setType', res.data.role.type);
					resolve();
				})
				.catch(() => {
					dispatch('logout');
					reject();
				});
		});
	},

	logout({ commit }) {
		return new Promise(resolve => {
			commit('cleanSession');
			resolve();
		});
	},

	/* Datos del usuario que ha iniciado sesion */
	activeUser({ commit }) {
		return new Promise((resolve, reject) => {
			api()
				.get(`/myinfo`)
				.then(res => {
					commit('updateSession', res.data);

					/* Cargo los canales */
					this.dispatch('irrigatorControl/loadChannels');
					this.dispatch('irrigatorControl/loadLocations');

					/* Asuntos posibles para la creacion de eventos recurrentes */
					this.dispatch('eventBookControl/loadEventSubjects');

					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	loadAssociationAreas({ commit }) {
		return new Promise((resolve, reject) => {
			api()
				.get(`/association-areas`)
				.then(res => {
					commit('setAssociationAreas', res.data);
					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	/* Users CRUD */
	loadUsers({ commit }, dataOptions) {
		this.dispatch('systemNotification/toggleLoading', true);

		return new Promise(resolve => {
			api()
				.get(
					`/association-users?_sort=name:asc&_start=${dataOptions.next}&_limit=${perPage}`
				)
				.then(async res => {
					if (dataOptions.next) {
						/* Si next esta definido y es mayor que cero, concateno la respuesta al resultado existente */
						const data = state.users.concat(res.data);
						commit('setUsers', data);
					} else {
						/* Si next no esta definido, seteo el contador de registros y el state de resultados */
						dataOptions.count = await api().get(
							`/association-users/count`
						);
						dataOptions.count = dataOptions.count.data;
						commit('setUsers', res.data);
					}

					/* Incremento el parametro next */
					dataOptions.next += perPage;

					/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
					if (dataOptions.next >= dataOptions.count) {
						dataOptions.next = dataOptions.count;
						commit('setNoMoreUsers', true);
					}

					/* Guardo en el state los parametros de la paginacion */
					commit('setUsersLastVisible', {
						count: dataOptions.count,
						next: dataOptions.next,
					});

					resolve();
				})
				.finally(
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	clearUsers({ commit }) {
		commit('setUsers', []);
		commit('setUsersLastVisible', {
			count: 0,
			next: 0,
		});
		commit('setNoMoreUsers', false);
	},

	readUser({ commit }, id) {
		return new Promise((resolve, reject) => {
			api()
				.get(`/association-users/${id}`)
				.then(res => {
					commit('setSelectedUser', res.data);
					resolve();
				})
				.catch(err => {
					reject(err);
				});
		});
	},

	unReadUser({ commit }) {
		commit('setSelectedUser', {});
	},

	saveUser({ state, commit }, data) {
		const users = utils.deepCopy(state.users);
		return new Promise((resolve, reject) => {
			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/save-user`, data)
				.then(res => {
					/* Si se ha pasado un id en la variable data, es por que se trata de un usuario existente y se debe actualizar el state */
					if (data.id) {
						/* Actualizo el listado de usuarios el state con los resultados */
						users.forEach((item, index) => {
							if (item.id == res.data.id) users[index] = res.data;
						});

						/* Si el usuario actualizado es el mismo de la sesion, se actualiza el state */
						if (state.user.id == res.data.id)
							commit('updateSession', res.data);
					} else {
						/* cargo el usuario nuevo al state */
						users.push(res.data);
					}

					commit('setUsers', users);

					resolve();
				})
				.catch(() => {
					reject();
				})
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	deleteUser({ commit }, data) {
		return new Promise((resolve, reject) => {
			api()
				.delete(`/association-users/${data}`)
				.then(res => {
					let users = utils.deepCopy(state.users);

					users = users.filter(item => item.id != res.data.id);
					commit('setUsers', users);

					resolve();
				})
				.catch(reject);
		});
	},

	async loadWatchmanList({ state, commit }) {
		if (state.user.association_area.code == 'sectionm') {
			let idAreaWatchman = state.associationAreas.reduce(
					(acc, curr) => (curr.code == 'watchman' ? curr.id : acc),
					'none'
				),
				assigned = state.user.assigned_users;

			let watchmans = await api().get(
				`/association-users?_sort=name:asc&_start=0&_limit=200&association_area=${idAreaWatchman}`
			);
			watchmans = watchmans.data.filter(item =>
				assigned.includes(item.user.id)
			);
			commit('setWatchmans', watchmans);
		} else if (state.user.association_area.code != 'watchman') {
			let idAreaWatchman = state.associationAreas.reduce(
				(acc, curr) => (curr.code == 'watchman' ? curr.id : acc),
				'none'
			);
			let idAreaSectionm = state.associationAreas.reduce(
				(acc, curr) => (curr.code == 'sectionm' ? curr.id : acc),
				'none'
			);

			await api()
				.get(
					`/association-users?_sort=name:asc&_start=0&_limit=200&association_area=${idAreaWatchman}`
				)
				.then(async res => commit('setWatchmans', res.data));

			await api()
				.get(
					`/association-users?_sort=name:asc&_start=0&_limit=200&association_area=${idAreaSectionm}`
				)
				.then(async res => commit('setSectionm', res.data));
		}
	},

	/* Establecimiento de conexion websocket */
	connectSocket({ rootState, commit }) {
		const storageName = process.env.VUE_APP_STORAGE_NAME;

		console.log('Intentando conectar con ', process.env.VUE_APP_WS);
		/* Estableciendo la conexion del usuario con el websocket */
		socket.emit('join', {
			userid: rootState.userControl.user.user.id,
			token: utils.getItemStorage(storageName).jwt,
		});

		socket.on('welcome', data => {
			console.log(
				`Conexion establecida con ${data.username} con el socket: ${data.socket}`
			);
		});

		socket.on('error:user', data => {
			console.log('Error de usuario', data);
		});

		socket.on('sesion:close', data => {
			if (data) commit('setDisconnected');
		});
	},
};

const mutations = {
	setType(state, data) {
		state.type = data;
	},

	setSession(state, data) {
		state.user = data.user;
		utils.setItemStorage(storageName, { jwt: data.jwt });
	},

	updateSession(state, data) {
		/* Se actualiza los datos del usuario al recargar la pagina */
		state.user = data;
	},

	cleanSession(state) {
		state.user = {};
		state.users = [];

		localStorage.clear();
	},

	setDisconnected(state) {
		state.disconnected = true;
		localStorage.clear();
	},

	setGeneralSettings(state, data) {
		state.generalSettings = Object.assign(state.generalSettings, data);
	},

	setUsers(state, data) {
		state.users = data;
	},

	setUsersLastVisible(state, data) {
		state.usersLastVisible = data;
	},

	setNoMoreUsers(state, data) {
		state.noMoreUsers = data;
	},

	setAssociationAreas(state, data) {
		state.associationAreas = data;
	},

	setSelectedUser(state, data) {
		state.selectedUser = data;
	},

	setWatchmans(state, data) {
		state.watchmans = data;
	},

	setSectionm(state, data) {
		state.sectionm = data;
	},
};

/*
creationTime: "Tue, 20 Oct 2020 14:18:19 GMT"
lastSignInTime: "Tue, 20 Oct 2020 14:53:28 GMT"
*/
export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
