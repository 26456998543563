import api from '@/plugins/api';

const perPage = Number(process.env.VUE_APP_IRRIGATORS_PER_PAGE);

const state = {
	files: [],
	filesLegal: [],
	filesLastVisible: { count: 0, next: 0 },
	noMoreFiles: false,
	lastUpdateFees: {},
};

const actions = {
	loadFiles({ state, commit }, dataOptions) {
		return new Promise(resolve => {
			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.get(
					`/file-update-controls?_sort=createdAt:desc&_start=${dataOptions.next}&_limit=${perPage}`
				)
				.then(async res => {
					if (dataOptions.next) {
						/* Si next esta definido y es mayor que cero, concateno la respuesta al resultado existente */
						const data = state.files.concat(res.data);
						commit('setFiles', data);
					} else {
						/* Si next no esta definido, seteo el contador de registros y el state de resultados */
						dataOptions.count = await api().get(
							`/file-update-controls/count`
						);
						dataOptions.count = dataOptions.count.data;
						commit('setFiles', res.data);
					}

					/* Incremento el parametro next */
					dataOptions.next += perPage;

					/* Si el parametro next supera al total de registros en la base de datos, lo seteo con el valor de count, y paso a true la bandera noMore */
					if (dataOptions.next >= dataOptions.count) {
						dataOptions.next = dataOptions.count;
						commit('setNoMoreFiles', true);
					}

					/* Guardo en el state los parametros de la paginacion */
					commit('setFilesLastVisible', {
						count: dataOptions.count,
						next: dataOptions.next,
					});

					resolve();
				})
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	refreshFiles({ dispatch, commit }) {
		commit('setFiles', []);
		commit('setFilesLastVisible', { count: 0, next: 0 });
		commit('setNoMoreFiles', false);

		dispatch('loadFiles', { count: 0, next: 0 });
	},

	uploadChannelData(context, file) {
		return new Promise((resolve, reject) => {
			var formdata = new FormData();
			formdata.append('files.excel', file, file.name);
			formdata.append('data', JSON.stringify({ type: 'channel' }));

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/file-update-controls`, formdata)
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	uploadLocationData(context, file) {
		return new Promise((resolve, reject) => {
			var formdata = new FormData();
			formdata.append('files.excel', file, file.name);
			formdata.append('data', JSON.stringify({ type: 'location' }));

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/file-update-controls`, formdata)
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	uploadIrrigatorData(context, file) {
		return new Promise((resolve, reject) => {
			var formdata = new FormData();
			formdata.append('files.excel', file, file.name);
			formdata.append('data', JSON.stringify({ type: 'irrigator' }));
			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/file-update-controls`, formdata)
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},
	uploadIrrigatorFile(context, file) {
		return new Promise((resolve, reject) => {

			var formdata = new FormData();

			if (file && file.name) { // Asegúrate de que el archivo existe y tiene un nombre
				formdata.append('files.file', file.file, file.name);
				formdata.append('data', JSON.stringify({
					name: file.name, // Usar el nombre del archivo como 'name'
					code: file.code, // Usar el valor fijo para 'code'
				}));
			} else {
				console.log('Archivo no válido o no proporcionado');
				reject('Archivo no válido o no proporcionado');
				return;
			}

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/irrigator-file-controls`, formdata)
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},
	deleteIrrigatorFile(context , code) {
		return new Promise((resolve, reject) => {
			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.delete(`/irrigator-file-controls/${code}`)
				.then(data => {
					resolve(data);
				})
				.catch(error => {
					console.error('Hubo el un problema con la operacion:', error);
					reject(error);
				})
				.finally(() => {
					this.dispatch('systemNotification/toggleLoading', false);
				});
		});
	},
	uploadBankData(context, file) {
		return new Promise((resolve, reject) => {
			var formdata = new FormData();
			formdata.append('files.excel', file, file.name);
			formdata.append('data', JSON.stringify({ type: 'bank' }));

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/file-update-controls`, formdata)
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	uploadIrrigatorFees(context, file) {
		return new Promise((resolve, reject) => {
			var formdata = new FormData();
			formdata.append('files.excel', file, file.name);
			formdata.append('data', JSON.stringify({ type: 'fees' }));

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/file-update-controls`, formdata)
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	uploadIrrigatorFeesPaids(context, file) {
		return new Promise((resolve, reject) => {
			var formdata = new FormData();
			formdata.append('files.excel', file, file.name);
			formdata.append('data', JSON.stringify({ type: 'feespaid' }));

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/file-update-controls`, formdata)
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},

	loadLastUpdateFees({ commit }) {
		api()
			.get('/file-update-controls/last-update-fees')
			.then(res => {
				commit('setLastUpdateFees', res.data);
			});
	},
	fetchFilesByCode({ commit }, code) {
		return api().get(`/irrigator-file-controls/${code}`) // Retornar directamente la promesa aquí
		.then(response => {
			commit('setFiles', response.data); // Supongamos que response.data contiene los datos que quieres
			console.log(response.data)
			return response.data; // Esto permite el encadenamiento de then() en el componente
		})
		.catch(error => {
			console.error('Error al cargar el archivo:', error);
			throw error; // Propagar el error para manejarlo en el componente
		});
  	},
	uploadFileLegal(context, file) {
		return new Promise((resolve, reject) => {

			var formdata = new FormData(); 
			
			if (file && file.name) { // Asegúrate de que el archivo existe y tiene un nombre
				formdata.append('files.file', file.file, file.name);
				formdata.append('data', JSON.stringify({
					name: file.name, // Usar el nombre del archivo como 'name'
				}));
			} else {
				reject('Archivo no válido o no proporcionado');
				return;
			}

			this.dispatch('systemNotification/toggleLoading', true);
			api()
				.post(`/file-legal-controls`, formdata) 
				.then(resolve)
				.catch(err => reject(err))
				.finally(() =>
					this.dispatch('systemNotification/toggleLoading', false)
				);
		});
	},
	fetchFilesLegal({ commit }) {
		return api().get('/file-legal-controls/')
		  .then(response => {
			commit('setFilesLegal', response.data);
			return response.data;
		  })
		  .catch(error => {
			throw error;
		  });
	  },
};

const mutations = {
	setFiles(state, data) {
		state.files = data;
	},
	setFilesLegal(state, data) {
		state.files = data;
	},
	setNoMoreFiles(state, data) {
		state.noMoreFiles = data;
	},

	setFilesLastVisible(state, data) {
		state.filesLastVisible = data;
	},

	setLastUpdateFees(state, data) {
		state.lastUpdateFees = data;
	},

};

export default {
	namespaced: true,
	state,
	actions,
	mutations,
};
