import axios from 'axios';
import utils from '@/utils';

export default () => {
	/* Nombre del almacenamiento */
	const storageName = process.env.VUE_APP_STORAGE_NAME;

	const http = axios.create({
		baseURL: process.env.VUE_APP_API,
		withCredentials: false,
		headers: {
			Authorization: {
				toString() {
					return utils.getItemStorage(storageName)
						? 'Bearer ' + utils.getItemStorage(storageName).jwt
						: '';
				},
			},
			'Content-Type': 'application/json',
			Accept: 'application/json',
		},
	});

	// Interceptor de solicitud
	http.interceptors.request.use(
		config => {
			// La logica que desee realizar
			return config;
		},
		error => {
			// La logica que desee realizar
			return Promise.reject(error);
		}
	);

	// Interceptor de respuesta
	http.interceptors.response.use(
		response => {
			// La logica que desee realizar

			if (
				response.data.errors &&
				response.data.errors[0].message == 'Invalid token.'
			) {
				localStorage.clear();
				window.location.reload();
			}

			return response;
		},
		error => {
			// La logica que desee realizar
			if (error.response.status == '401') {
				localStorage.clear();
			}
			return Promise.reject(error);
		}
	);

	return http;
};
