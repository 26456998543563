import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './stores';
import './plugins/editor';

Vue.config.productionTip = false;

import utils from '@/utils';
Vue.prototype.$utils = utils;

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App),
}).$mount('#app');

// Command: docker run -it -p 8080:8080 --rm -v $(pwd):/srv -w /srv node:10.16.3 bash
